$(function () {
    $(".c-member-compass-nav-toggle").click(function () {
        $(".c-event-nav").slideToggle();
        $(".c-member-compass-nav-toggle").toggleClass("c-member-compass-nav-toggle--expanded");
    });

    $(".c-event-nav__item").click(function () {
        if ($(".c-member-compass-nav-toggle").is(":visible")) {
            $(".c-event-nav").slideToggle();
            $(".c-member-compass-nav-toggle").toggleClass("c-member-compass-nav-toggle--expanded");
        }
    });

    $(window).resize(function () {
        //If they resize the page below large breakpoint and then make it larger again, the nav could be hidden due to the mobile toggles above
        if ($(window).width() > 992 && !$(".c-event-nav").is(":visible"))
            $(".c-event-nav").toggle();
    });

    if (window.location && window.location.hash) {
        loadPageAndSection(window.location.hash);
    }

    function UpdateBackgroundHeight() {
        var contactInfoHeight = $(".overlay .contact-info").height();
        var logoHeight = $(".event-logo img").height();
        if (contactInfoHeight < logoHeight)
            contactInfoHeight = logoHeight;
        var overlayPadding = parseInt($(".well.box .background .overlay").css("padding-top")) * 2;
        if (breakpoint().indexOf("phone") == -1) {
            $(".well.box .background").height(contactInfoHeight + overlayPadding);
            $(".logo-container").height(contactInfoHeight);
        }
        else {
            $(".logo-container").height("auto");
            $(".well.box .background").height(contactInfoHeight + overlayPadding + $(".logo-container").height());
        }
    }

    if ($(".event-logo img").length > 0) {
        $(".event-logo").imagesLoaded().always(function (e) {
            UpdateBackgroundHeight();
        });
    } else {
        UpdateBackgroundHeight();
    }

    $(window).resize(UpdateBackgroundHeight);

    $("[data-toggle]").tooltip();
});

function loadPageAndSection(hash) {
    hash = hash.replace('#', '');
    var listOfPages = $(".c-event-nav a")
        .filter(function () { return this.href && this.href.indexOf('#') > -1 })
        .map(function () { return this.href.split('#')[1] }).toArray();

    // get pages that match the start of the target href and get the longest one which would be the best match
    var match = listOfPages.filter(function (page) { return hash.startsWith(page) })
        .sort(function (a, b) { return b.length - a.length })[0];

    var fieldName = hash.replaceAll(match, '').replaceAll('_', ' ')
        .replaceAll('-', ' ').replaceAll('#', '').trim().toLowerCase();

    $(".c-event-nav a[href='#" + match + "']").tab('show');
    var foundField = $("#" + match + " label").filter(function () { return $(this).text().toLowerCase() == fieldName });

    if (foundField.length > 0) {
        setTimeout(function () {
            foundField[0].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }, 500)
    }

    return true;
}

function updateUrl(hash) {
    if (hash) {
        history.pushState(null, null, hash);
    }
}

$(window).on('popstate', () => {
    if (window.location && window.location.hash) {
        loadPageAndSection(window.location.hash);
    }
});


// This is a fix for an issue specific to the first element of the rrsb buttons on certain themes. To keep the fix working across all themes
// I'm simply checking if the neighbor buttons already have the expected ".small" class. If so, add it to the first button. The rrsb library
// will adjust the text visibility automatically once the class is applied. Theme's that do not use this class will therefore not be impacted.
document.addEventListener("DOMContentLoaded", function () {
    const rrssbButtons = document.querySelector(".rrssb-buttons");
    if (rrssbButtons) {
        const hasSmallClass = Array.from(rrssbButtons.children).some(li => li.classList.contains("small"));

        if (hasSmallClass) {
            const firstLi = rrssbButtons.querySelector("li:first-child");
            if (firstLi && !firstLi.classList.contains("small")) {
                firstLi.classList.add("small");
            }
        }
    }
});
